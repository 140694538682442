<template>
  <el-dialog
    :ref="dialoginfo.modlecode + 'dialog'"
    :title="dialoginfo.name"
    v-model="visiable"
    width="600px"
    :show-close="false"
    @close="cleardata"
    center
  >
    <span>
      <el-form
        :ref="dialoginfo.modlecode + 'dialogform'"
        inline
        :model="thisdata"
        :rules="thisrules"
        label-width="100px"
      >
        <el-form-item label="客户名称" prop="v_name">
          <el-input
            :disabled="true"
            v-model="thisdata.v_name"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="合同名称" prop="v_name">
          <CurrentinformationSelect
            :rowcode="Currentinformationdata"
            :iscleanable="false"
            @datachange="Currentinformationchangs"
          />
        </el-form-item>
      </el-form>
    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visiable = false">取 消</el-button>
        <el-button
          type="primary"
          v-show="dialoginfo.modle != 'view'"
          @click="savedata"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script >
//import { ElMessage } from "element-plus";
import CurrentinformationSelect from "@/controls/CurrentinformationSelect.vue";

import { ElMessage } from "element-plus";
import axios from "axios";

export default {
  emits: ["changedata", "dialogclose"],

  components: { CurrentinformationSelect },
  props: {
    dialoginfo: { required: true },
    dialogdata: { required: true },
    isvisiable: {},
  },
  data() {
    const validateword = (rule, value, callback) => {
      if (value.toString().match("^[^a-z A-Z]?[^a-z A-Z 0-9]+")) {
        callback(new Error("只可输入字母"));
      } else if (value == "") {
        callback(new Error("标识不可为空"));
      } else {
        callback();
      }
    };
    return {
      Currentinformationdata: "",
      goodstypewi: "250px",
      thiseddata: {
        keword: "",
        temparea: 1,
        goodstype1: "",
        goodstype2: "",
        goodstype3: "",
        goodstype4: "",
        goodstype5: "",
        goodstype6: "",
      },
      dtlvisiablegp: false,
      ssvalue: {},
      ssdatas: [],
      dtlvisiable: false,
      tempareas: [
        { value: 1, label: "常温" },
        { value: 2, label: "冰鲜" },
        { value: 3, label: "冷冻" },
      ],
      dtldata: { type: "1", v_name: "", mark: "", remark: "" },
      dtldatarules: {
        v_name: [
          { required: false, message: "本项目不可为空！！", trigger: "blur" },
        ],
        mark: [{ required: true, validator: validateword, trigger: "blur" }],
      },
      thisdata: {},
      visiable: false,
      yesno: [
        { label: "是", value: "1" },
        { label: "否", value: "99" },
      ],
      dtltypes: [
        { label: "通用数据", value: "1" },
        { label: "专用数据", value: "2" },
      ],
      thisrules: {
        v_name: [
          { required: true, message: "本项目不可为空！！", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    isvisiable: function () {
      if (this.isvisiable) {
        this.thisdata = JSON.parse(JSON.stringify(this.dialogdata));
        this.cleardtldata();
        this.visiable = true;
        console.log("dialog get", this.dialogdata);
        console.log("dialog info ", this.dialoginfo);
        this.getdata();
        this.Currentinformationdata=this.dialogdata['contactcode'];
      }
    },
  },
  mounted() {
    this.Currentinformationdata=this.dialogdata['contactcode'];
    console.log("buyer_dg_getdata",this.dialogdata);
  },
  methods: {
    Currentinformationchangs(val){
        this.thisdata.contactcode=val.row_code;
    },
    tempareaforma(row, column, value) {
      if (value == 1) {
        return "常温";
      } else if (value == 2) {
        return "冰鲜";
      } else if (value == 3) {
        return "冷冻";
      } else {
        return "数据错误";
      }
    },
    deldata(val) {
      if (val.sid > 0) {
        this.thisdata.deletdtl.push(val);
      }
      for (var i = 0; i < this.thisdata.dtl.length; i++) {
        if (this.thisdata.dtl[i].row_code == val.row_code) {
          this.thisdata.dtl.splice(i, 1);
          return;
        }
      }
    },
    goodstype1cg(val) {
      console.log(val);
      this.thiseddata.goodstype1 = val[0].value;
      this.thiseddata.goodstype1_v = val[0].label;
    },
    goodstype2cg(val) {
      this.thiseddata.goodstype2 = val[0].value;
      this.thiseddata.goodstype2_v = val[0].label;
    },
    goodstype3cg(val) {
      this.thiseddata.goodstype3 = val[0].value;
      this.thiseddata.goodstype3_v = val[0].label;
    },
    goodstype4cg(val) {
      this.thiseddata.goodstype4 = val[0].value;
      this.thiseddata.goodstype4_v = val[0].label;
    },
    goodstype5cg(val) {
      this.thiseddata.goodstype5 = val[0].value;
      this.thiseddata.goodstype5_v = val[0].label;
    },
    goodstype6cg(val) {
      this.thiseddata.goodstype6 = val[0].value;
      this.thiseddata.goodstype6_v = val[0].label;
    },

    dtlsave() {
      this.$bus.$emit("showloading");
      let needadd = true;

      this.thisdata.dtl.map((val) => {
        if (
          val.keword == this.thiseddata.keword &&
          val.temparea == this.thiseddata.temparea &&
          val.goodstype1 == this.thiseddata.goodstype1 &&
          val.goodstype2 == this.thiseddata.goodstype2 &&
          val.goodstype3 == this.thiseddata.goodstype3 &&
          val.goodstype4 == this.thiseddata.goodstype4 &&
          val.goodstype5 == this.thiseddata.goodstype5 &&
          val.goodstype6 == this.thiseddata.goodstype6
        ) {
          ElMessage.error({
            message: "已存在相同数据！！！",
            duration: 2000,
            dangerouslyUseHTMLString: true,
            center: false,
          });
          needadd = false;
        }
      });
      if (needadd) {
        this.thiseddata.status = 1;
        this.thiseddata.sid = 0;
        this.thiseddata.row_code =
          "ad" +
          Date.parse(new Date()) +
          "-" +
          parseInt(Math.random() * (9999 - 1000 + 1) + 1000, 10);
        this.thiseddata.ordernum = this.thisdata.dtl.length;
        this.thiseddata.h_row_code = this.thisdata.row_code;
        this.thisdata.dtl.push(JSON.parse(JSON.stringify(this.thiseddata)));
      }
      this.dtlvisiable = false;
      this.$bus.$emit("hideloading");
    },
    cleardtldata() {
      this.thiseddata.sid = 0;
      this.thiseddata.row_code = "";
      this.thiseddata.h_row_code = "";
      this.thiseddata.goodstype1 = "";
      this.thiseddata.goodstype2 = "";
      this.thiseddata.goodstype3 = "";
      this.thiseddata.goodstype4 = "";
      this.thiseddata.goodstype5 = "";
      this.thiseddata.goodstype6 = "";
      this.thiseddata.keword = "";
      this.thiseddata.ordernum = "";
      this.thiseddata.goodstype1_v = "";
      this.thiseddata.goodstype2_v = "";
      this.thiseddata.goodstype3_v = "";
      this.thiseddata.goodstype4_v = "";
      this.thiseddata.goodstype5_v = "";
      this.thiseddata.goodstype6_v = "";
      this.thiseddata.status = "";
      this.thiseddata.temparea = 1;
      console.log("closedtladd", this.thiseddata);
    },

    tableheadClass() {
      return "text-align:center";
    },
    getdata() {},
    cleardata() {
      //this.thisdata = {};
      this.$refs[this.dialoginfo.modlecode + "dialogform"].clearValidate([
        "v_name",
      ]);
      console.log("closedialog");
      this.$emit("dialogclose", {});
    },
    savedata() {
      console.log("savedata", this.thisdata);

      this.$bus.$emit("showloading");
      this.$refs[this.dialoginfo.modlecode + "dialogform"].validate((val) => {
        if (val) {
          axios
            .post("/" + this.dialoginfo.modlecode + "/save", {
              data: this.thisdata,
            })
            .then(() => {
              this.$emit("changedata", {});
              this.visiable = false;
              this.$bus.$emit("hideloading");
            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        } else {
          this.$bus.$emit("hideloading");
        }
      });
      //this.$bus.$emit("hideloading");
    },

    opendtladd() {
      this.dtlvisiable = true;
    },
  },
};
</script>
<style scoped>
:deep(.el-transfer-panel) {
  width: 300px;
}
</style>
