<template>
    <el-select
      v-model="localcode"
      :filterable="true"
      :style="'width:' + width + ';'"
      collapse-tags
      reserve-keyword
      remote
      :remote-method="getdata"
      :multiple="ismultiple"
      :clearable="iscleanable"
      @change="datacg"
      default-first-option
      :disabled="isdisabled"
    >
      <el-option
        v-for="item in localdatas"
        :key="item.row_code"
        :label="item.v_name + '[' + item.accountingunit_v + ']<' + item.remark+'>'"
        :value="item.row_code"
      >
      </el-option>
    </el-select>
  </template>
  <script>
  import axios from "axios";
  
  export default {
    emits: ["datachange"],
    props: {
      rowcode: { required: true },
      status: { default: "" },
      iscleanable: { default: false },
      ismultiple: { default: false },
      isdisabled: { default: false },
      width: { default: "320px" },
      placeholder: { default: "选择账号" },
    },
  
    data() {
      return {
        localcode: this.code,
        localdatas: [],
        serchdata: { codename: "", status: this.status, code: this.rowcode },
      };
    },
    watch: {
      rowcode: function () {
        console.log("account change watch", this.rowcode, this.localcode);
        if (this.rowcode != this.localcode) {
          this.serchdata.code=this.rowcode;
          this.getdata("");
          this.localcode = this.rowcode;
        }
      },
    },
    mounted() {

        this.getdata("");
    },
    methods: {
      getdata(val) {
        this.serchdata.codename = val;
        clearTimeout(this._getgoods);
        this._getgoods = setTimeout(() => {
          axios
            .post("/currentinformation/getselelist", { data: this.serchdata })
            .then((response) => {
              this.localdatas = response;
            });
        }, 300);
      },
      datacg(val) {
        if (this.ismultiple) {
          let tpdata = Array();
          val.map((valmx) => {
            for (var i = 0; i < this.localdatas.length; i++) {
              if (this.localdatas[i].row_code == valmx) {
                tpdata.push(this.localdatas[i]);
                break;
              }
            }
          });
          this.$emit("datachange", tpdata);
          return;
        } else {
          for (var i = 0; i < this.localdatas.length; i++) {
            if (this.localdatas[i].row_code == val) {
              this.$emit("datachange", this.localdatas[i]);
              return;
            }
          }
        }
      },
    },
  };
  </script>
  <style scoped>
  .el-select-dropdown__list {
    text-align: left;
  }
  </style>