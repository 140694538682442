<template>
  <el-table
    :data="tabledata"
    style="width: 100%; margin-top: 5px"
    :height="tableheight"
    :header-cell-style="tableheadClass"
    stripe
    row-key="row_code"
    border
    default-expand-all
    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
  >
    <el-table-column prop="v_name" label="项目名称" width="480">
    </el-table-column>

    <el-table-column prop="status_v" label="项目状态" width="180">
    </el-table-column>
    <el-table-column
      prop="is_leaf"
      label="项目类型"
      :formatter="isleaffor"
      width="180"
    >
    </el-table-column>
    <el-table-column
      prop="remark"
      label="备注"
      width="180"
    >
    </el-table-column>

    <el-table-column fixed="right" width="120">
      <template #header>
        <el-button
          v-show="viewlist.add"
          @click="openadd()"
          size="small"
          :disabled="!operar.add"
          icon="el-icon-plus"
          >新建
        </el-button>
        <div v-show="!viewlist.add">操作</div>
      </template>
      <template #default="scope">
        <el-button @click="openview(scope.row)" type="text" size="small"
          >查看</el-button
        >
        <el-button
          @click="openedit(scope.row)"
          type="text"
          size="small"
          :disabled="!operar.edit"
          v-show="
            (scope.row.is_view == 1 || scope.row.upcode != '-1') &&
            scope.row.status != 99 &&
            viewlist.edit
          "
          >编辑
        </el-button>
        <el-button
          @click="deldata(scope.row)"
          type="text"
          size="small"
          v-show="
            (scope.row.is_view == 1 || scope.row.upcode != '-1') &&
            scope.row.status != 99 &&
            viewlist.del
          "
          :disabled="!operar.del"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <ModelDialogVue
    :dialoginfo="dialoginfo"
    :dialogdata="dialogdata"
    :isvisiable="dialogvis"
    @changedata="flashdata"
    @dialogclose="editclose"
  ></ModelDialogVue>
</template>
  <script >
import ModelDialogVue from "./MainDialog.vue";
import {  ElMessageBox } from "element-plus";
import axios from "axios";
export default {
  components: { ModelDialogVue },
  emits: [],
  props: {
    searchdata: { required: true },
    selfmodul: { required: true },
    areaheight: { required: true },
  },
  data() {
    return {
      dialogvis: false,
      tableheight: 60,

      dialoginfo: { modlecode: this.selfmodul["code"] },
      dialogdata: {},
      tabledata: [],
      mysearch: {},
      operar: {
        conditionstr: "",
        add: false,
        edit: false,
        del: false,
        check: false,
        uncheck: false,
      },

      viewlist: {
        add: false,
        edit: false,
        del: false,
        check: false,
        uncheck: false,
      },
    };
  },
  beforeCreate() {},
  mounted() {
    let operardata = JSON.parse(localStorage.getItem("authlist"));
    let controldata = operardata[this.selfmodul["code"]].operation;
    console.log(controldata);
    this.operar.add = (controldata & 128) == 128;
    this.operar.del = (controldata & 64) == 64;
    this.operar.edit = (controldata & 32) == 32;
    this.operar.check = (controldata & 16) == 16;
    this.operar.uncheck = (controldata & 8) == 8;
    this.operar.conditionstr = operardata[this.selfmodul["code"]].conditionstr;

    operardata = JSON.parse(localStorage.getItem("viewlist"));
    controldata = operardata[this.selfmodul["code"]].operation;
    this.viewlist.add = (controldata & 128) == 128;
    this.viewlist.del = (controldata & 64) == 64;
    this.viewlist.edit = (controldata & 32) == 32;
    this.viewlist.check = (controldata & 16) == 16;
    this.viewlist.uncheck = (controldata & 8) == 8;

    console.log(this.operar);
    this.flashdata();

    console.log("areaheigh", this.areaheight);
  },
  watch: {
    searchdata: function () {
      if (JSON.stringify(this.mysearch) != JSON.stringify(this.searchdata)) {
        //查询条件变动，重新拉取数据
        this.mysearch = JSON.parse(JSON.stringify(this.searchdata));
        this.flashdata();
      }
    },
    areaheight: function () {
      this.$nextTick(() => {
        this.tableheight = this.areaheight - 253;
      });
    },
  },
  methods: {
    isleaffor(row, column, value) {
      if (value == 1) {
        return "合同";
      } else if (value == 0) {
        return "往来";
      } else {
        return "数据错误";
      }
    },

    editclose(data) {
      this.dialogvis = false;
      console.log(data);
    },
    flashdata() {
      this.$bus.$emit("showloading");
      axios
        .post("/" + this.selfmodul["code"] + "/getlist", {
          data: this.searchdata,
        })
        .then((response) => {
          this.tabledata = response;
          this.dialoginfo.alldatas = response;
          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    openview(data) {
      console.log("view", data);
      this.dialoginfo.name = "查看往来户";
      this.dialoginfo.modle = "view";
      this.dialogdata = data;
      this.dialogvis = true;
    },

    openedit(data) {
      console.log("edit", data);
      this.dialoginfo.name = "编辑往来户";
      this.dialoginfo.modle = "edit";
      this.dialogdata = data;
      this.dialogvis = true;
    },
    openadd() {
      this.dialogdata = {
        is_leaf: 1,
        title: "",
        controller: "",
        up_code: "",
        view_order: 0,
        status: 1,
      };
      this.dialoginfo.name = "新建往来户";
      this.dialoginfo.modle = "add";
      this.dialogvis = true;
    },
    deldata(data) {
      let mess='';
      if(data.is_leaf==1){
        mess='本操作不可撤销！！！！！确定执行删除操作吗？？';
      }else{
        mess='当前节点为汇总节点，删除节点将同时将下级节点全部删除，本操作不可撤销！！！！ 确定执行删除操作吗？？';
      }
      ElMessageBox.confirm(
        mess,
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        axios
          .post("/" + this.selfmodul["code"] + "/delet", { data: data })
          .then(() => {
            this.flashdata();
            this.$bus.$emit("hideloading");
          })
          .catch(() => {
            this.$bus.$emit("hideloading");
          });
      });
    },

    tableheadClass() {
      return "text-align:center";
    },
  },
};
</script>
  <style scoped>
</style>