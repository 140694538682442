<template>
  <el-dialog
    :ref="dialoginfo.modlecode + 'dialog'"
    :title="dialoginfo.name"
    v-model="visiable"
    width="1300px"
    :show-close="false"
    @close="cleardata"
    center
  >
    <span>
      <el-form
        :ref="dialoginfo.modlecode + 'dialogform'"
        inline
        :model="thisdata"
        :rules="thisrules"
        label-width="100px"
      >
        <el-form-item label="项目编号" prop="v_code">
          <el-input
            :disabled="dialoginfo.modle == 'view'"
            v-model="thisdata.v_code"
            style="width: 80px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="项目名称" prop="v_name">
          <el-input
            :disabled="true"
            v-model="thisdata.v_name"
            style="width: 220px"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item label="摘要" prop="remark">
          <el-input
            :disabled="dialoginfo.modle == 'view'"
            v-model="thisdata.remark"
            style="width: 280px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="所属往来" prop="upname">
          <el-input
            :disabled="true"
            v-model="thisdata.upname"
            style="width: 220px"
            autocomplete="off"
          />
        </el-form-item>

        <br />
      </el-form>

      <el-tabs type="card" v-model="activeTab">
        <el-tab-pane
          key="deadline"
          label="合同信息"
          name="deadline"
          :closable="false"
          ref="deadline"
        >
          <el-table
            :data="thisdata.deadlines"
            style="width: 100%; margin-top: 5px"
            :height="415"
            :header-cell-style="tableheadClass"
            stripe
            row-key="row_code"
            border
            default-expand-all
            :tree-props="{}"
          >
            <el-table-column
              prop="contactsnum"
              label="合同编号"
              width="220"
              align="center"
            />
            <el-table-column
              prop="v_name"
              label="合同期限"
              width="320"
              align="center"
            >
              <template #default="socped">
                {{
                  new Date(parseInt(socped.row.starttime)).getFullYear() +
                  "-" +
                  (new Date(parseInt(socped.row.starttime)).getMonth() + 1 < 10
                    ? "0" +
                      (new Date(parseInt(socped.row.starttime)).getMonth() + 1)
                    : new Date(parseInt(socped.row.starttime)).getMonth() + 1) +
                  "-" +
                  new Date(parseInt(socped.row.starttime)).getDate() +
                  "  至  " +
                  new Date(parseInt(socped.row.endtime)).getFullYear() +
                  "-" +
                  (new Date(parseInt(socped.row.endtime)).getMonth() + 1 < 10
                    ? "0" +
                      (new Date(parseInt(socped.row.endtime)).getMonth() + 1)
                    : new Date(parseInt(socped.row.endtime)).getMonth() + 1) +
                  "-" +
                  new Date(parseInt(socped.row.endtime)).getDate()
                }}
              </template>
            </el-table-column>

            <el-table-column
              prop="status"
              label="合同状态"
              width="180"
              align="center"
              :formatter="hetongstatus"
            />

            <el-table-column prop="remark" label="摘要" width="310">
            </el-table-column>

            <el-table-column fixed="right" width="120">
              <template #header>
                <el-button
                  @click="this.deadlineeditvisiable = true"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  icon="el-icon-plus"
                  >新建
                </el-button>
              </template>
              <template #default="scope">
                <el-button
                  @click="editdeadlinedata(scope.row)"
                  type="text"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          key="Credit"
          label="授信管理"
          name="Credit"
          :closable="false"
          ref="Credit"
        >
          <div style="height: 420px; width: 100%">
            <el-form label-width="100px" inline>
              <el-form-item label="会计单位">
                <el-select
                  v-model="thisdata.accountingunit"
                  :filterable="true"
                  :style="'width:260px;'"
                  collapse-tags
                  reserve-keyword
                  default-first-option
                  :disabled="dialoginfo.modle == 'view'"
                >
                  <el-option
                    v-for="item in accunits"
                    :key="item.row_code"
                    :label="item.v_name"
                    :value="item.row_code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="授信金额">
                <el-input-number
                  :disabled="dialoginfo.modle == 'view'"
                  v-model="creditamount"
                  style="width: 120px"
                  :controls="false"
                  @blur="numnerblur"
                />
              </el-form-item>

              <el-form-item label="核销方式">
                <el-select
                  v-model="thisdata.settle_type"
                  :disabled="dialoginfo.modle == 'view'"
                >
                  <el-option
                    v-for="item in settletypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="发票要求">
                <el-select
                  v-model="thisdata.process_type"
                  :disabled="dialoginfo.modle == 'view'"
                >
                  <el-option
                    v-for="item in processtypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>

              <el-form-item
                label="自动开票"
                :disabled="dialoginfo.modle == 'view'"
                v-show="false"
              >
                <el-select
                  v-model="thisdata.process_enable2"
                  style="width: 60px"
                  :disabled="dialoginfo.modle == 'view'"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in yesorno"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="显示属性"
                :disabled="dialoginfo.modle == 'view'"
              >
                <el-select
                  v-model="thisdata.isview"
                  style="width: 60px"
                  :disabled="dialoginfo.modle == 'view'"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in yesorno"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane
          key="account"
          label="账号管理"
          name="account"
          :closable="false"
          ref="account"
        >
          <el-table
            :data="thisdata.acclist"
            style="width: 100%; margin-top: 5px"
            :height="415"
            :header-cell-style="tableheadClass"
            stripe
            row-key="row_code"
            border
            default-expand-all
            :tree-props="{}"
          >
            <el-table-column prop="v_name" label="账户名称" width="320">
            </el-table-column>

            <el-table-column
              prop="v_num"
              label="账号"
              width="320"
              align="center"
            />

            <el-table-column prop="v_bank" label="开户行" width="320">
            </el-table-column>

            <el-table-column prop="defaultcode" label="默认" width="60">
              <template #default="scope">
                <el-radio
                  class="radio"
                  :disabled="dialoginfo.modle == 'view'"
                  v-model="thisdata.accdefault"
                  :label="scope.row.bankcode"
                  @change="setradioRow(scope.row.bankcode)"
                  ><i></i
                ></el-radio>
              </template>
            </el-table-column>

            <el-table-column fixed="right" width="120">
              <template #header>
                <el-button
                  @click="this.acceditvisiable = true"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  icon="el-icon-plus"
                  >新建
                </el-button>
              </template>
              <template #default="scope">
                <el-button
                  @click="delaccdata(scope.row)"
                  type="text"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          key="period"
          label="账期设置"
          name="period"
          :closable="false"
          ref="period"
          >对账日：
          <el-input
            :disabled="dialoginfo.modle == 'view'"
            v-model="period_day1"
            style="width: 150px"
          ></el-input
          ><el-tooltip
            popper-class="toolpitscs"
            effect="light"
            placement="right-start"
            content="
          &nbsp;&nbsp;&nbsp;&nbsp;填写方式如下（'/'表示或，'X'表示数字）:&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;1、留空：则对账日为出库单审核日； &#10;
          &nbsp;&nbsp;&nbsp;&nbsp;2、周一/周二/……/周六/周日/X日(X为1-31)：则对账日为出库单审核日后第一个满足条件的日期，即填写周日为出库单审核后的第一个周日，3日为出库单审核后的第一个3日。如填写29日、30日、31日且当月没有该日期，则为当月最后一日；&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;3、X天：则对账日为出库单审核日期后的第X天；&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;4、X天周一/X天X日/X月X日：则对账日为出库单审核日延后指定时间后满足条件的日期。5天周二即出库单审核日加5天后继续往后算的第一个周二，1月4日即审核日加1自然月天数（计算区分大小月）后继续往后算的第一个4日；&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;注意：比对日期等于对账日期时视为满足条件，即出库单审核日期为周一，对账日填写周一时，该出库单对账日为审核当日；如希望该出库单对账日为下一个周一，本栏可填写为1天周一。"
          >
            <span
              >&nbsp;&nbsp;?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            ></el-tooltip
          >
          开票日：
          <el-input
            :disabled="dialoginfo.modle == 'view'"
            v-model="period_day2"
            style="width: 150px"
          ></el-input
          ><el-tooltip
            popper-class="toolpitscs"
            effect="light"
            placement="right-start"
            content="
          &nbsp;&nbsp;&nbsp;&nbsp;填写方式如下（'/'表示或，'X'表示数字）:&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;1、留空：则开票日为实际对账日； &#10;
          &nbsp;&nbsp;&nbsp;&nbsp;2、周一/周二/……/周六/周日/X日(X为1-31)：则开票日为实际对账日后第一个满足条件的日期，即填写周日为实际对账后的第一个周日，3日为出实际对账后的第一个3日。如填写29日、30日、31日且当月没有该日期，则为当月最后一日；&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;3、X天：则开票日为实际对账后的第X天；&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;4、X天周一/X天X日/X月X日：则开票日为实际对账后延后指定时间后满足条件的日期。5天周二即实际对账日加5天后继续往后算的第一个周二，1月4日即实际对账日加1自然月天数（计算区分大小月）后继续往后算的第一个4日；&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;注意：比对日期等于开票日期时视为满足条件，即实际对账日期为周一，开票日填写周一时，该账单开票日为对账当日；如希望该单据开票日为下一个周一，本栏可填写为1天周一；先款客户本栏留空即可；"
          >
            <span
              >&nbsp;&nbsp;?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            ></el-tooltip
          >

          回款日：
          <el-input
            :disabled="dialoginfo.modle == 'view'"
            v-model="period_day3"
            style="width: 150px"
          ></el-input
          ><el-tooltip
            popper-class="toolpitscs"
            effect="light"
            placement="right-start"
            content="
          &nbsp;&nbsp;&nbsp;&nbsp;填写方式如下（'/'表示或，'X'表示数字）:&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;1、留空：则回款日为实际开票日； &#10;
          &nbsp;&nbsp;&nbsp;&nbsp;2、周一/周二/……/周六/周日/X日(X为1-31)：则回款日为实际开票日后第一个满足条件的日期，即填写周日为实际开票后的第一个周日，3日为出实际开票后的第一个3日。如填写29日、30日、31日且当月没有该日期，则为当月最后一日；&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;3、X天：则回款日为实际开票后的第X天；&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;4、X天周一/X天X日/X月X日：则回款日为实际开票后延后指定时间后满足条件的日期。5天周二即实际开票日加5天后继续往后算的第一个周二，1月4日即实际开票日加1自然月天数（计算区分大小月）后继续往后算的第一个4日；&#10;
          &nbsp;&nbsp;&nbsp;&nbsp;注意：比对日期等于回款日期时视为满足条件，即实际开票日期为周一，回款日填写周一时，该账单回款日为开票当日；如希望该单据回款日为下一个周一，本栏可填写为1天周一；"
          >
            <span
              >&nbsp;&nbsp;?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            ></el-tooltip
          >

          <el-button
            @click="addzqs"
            :disabled="dialoginfo.modle == 'view'"
            size="small"
            icon="el-icon-plus"
            >添加
          </el-button>
          <el-table
            :data="thisdata.periods"
            style="width: 100%; margin-top: 5px"
            :height="375"
            :header-cell-style="tableheadClass"
            stripe
            row-key="row_code"
            border
            default-expand-all
            :tree-props="{}"
          >
            <el-table-column
              prop="data1"
              label="对账日"
              :formatter="formatter_data"
              width="320"
            >
            </el-table-column>
            <el-table-column
              prop="data2"
              label="开票日"
              :formatter="formatter_data"
              width="230"
            ></el-table-column>
            <el-table-column
              prop="data3"
              label="回款日"
              :formatter="formatter_data"
              width="260"
            ></el-table-column>

            <el-table-column fixed="right" width="120">
              <template #header> 操作 </template>
              <template #default="scope">
                <el-button
                  @click="delperiodsdata(scope.row)"
                  type="text"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          key="indiscount"
          label="票面折扣"
          name="indiscount"
          :closable="false"
          ref="indiscount"
        >
          <el-table
            :data="indiscounts"
            style="width: 100%; margin-top: 5px"
            :height="415"
            :header-cell-style="tableheadClass"
            stripe
            row-key="row_code"
            border
            default-expand-all
            :tree-props="{}"
          >
            <el-table-column
              prop="v_name"
              label="项目名称"
              width="320"
            ></el-table-column>
            <el-table-column label="生效日期范围" width="300" align="center">
              <template #default="scope">
                {{ scope.row.begintime_date + " 至 " + scope.row.endtime_date }}
              </template>
            </el-table-column>
            <el-table-column
              prop="dtltype"
              label="折扣类型"
              width="90"
              :formatter="formatterdtltype"
            ></el-table-column>
            <el-table-column
              prop="remark"
              label="摘要"
              width="400"
            ></el-table-column>

            <el-table-column fixed="right" width="120">
              <template #header>
                <el-button
                  @click="discountedvisiable = true"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  icon="el-icon-plus"
                  >新建
                </el-button>
              </template>
              <template #default="scope">
                <el-button
                  @click="opdiscountdata(scope.row)"
                  type="text"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  >打开</el-button
                >
                <el-button
                  @click="delindiscountdata(scope.row)"
                  type="text"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          key="outdiscount"
          label="票外折扣"
          name="outdiscount"
          :closable="false"
          ref="outdiscount"
        >
          <el-table
            :data="outdiscounts"
            style="width: 100%; margin-top: 5px"
            :height="415"
            :header-cell-style="tableheadClass"
            stripe
            row-key="row_code"
            border
            default-expand-all
            :tree-props="{}"
          >
            <el-table-column
              prop="v_name"
              label="项目名称"
              width="320"
            ></el-table-column>
            <el-table-column label="生效日期范围" width="300" align="center">
              <template #default="scope">
                {{ scope.row.begintime_date + " 至 " + scope.row.endtime_date }}
              </template>
            </el-table-column>
            <el-table-column
              prop="dtltype"
              label="折扣类型"
              width="90"
              :formatter="formatterdtltype"
            ></el-table-column>
            <el-table-column
              prop="remark"
              label="摘要"
              width="400"
            ></el-table-column>

            <el-table-column fixed="right" width="120">
              <template #header>
                <el-button
                  @click="discountedvisiable = true"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  icon="el-icon-plus"
                  >新建
                </el-button>
              </template>
              <template #default="scope">
                <el-button
                  @click="opdiscountdata(scope.row)"
                  type="text"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  >打开</el-button
                >
                <el-button
                  @click="delindiscountdata(scope.row)"
                  type="text"
                  size="small"
                  :disabled="dialoginfo.modle == 'view'"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visiable = false">取 消</el-button>
        <el-button
          type="primary"
          v-show="dialoginfo.modle != 'view'"
          @click="savedata"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog
    title="添加银行账号"
    v-model="acceditvisiable"
    width="840px"
    :show-close="false"
    center
  >
    <span>
      <el-form label-width="100px">
        <el-form-item label="选择银行账号">
          <AccountinfoSelectVue
            :rowcode="accounteditdata"
            :iscleanable="true"
            @datachange="accoutselechangs"
          />
        </el-form-item>
        <el-form-item label="账户名称">
          <el-input
            :disabled="true"
            v-model="thisaccedit.v_name"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="账 号">
          <el-input
            :disabled="true"
            v-model="thisaccedit.v_num"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="开户行">
          <el-input
            :disabled="true"
            v-model="thisaccedit.v_bank"
            style="width: 260px"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="acceditvisiable = false">取 消</el-button>
        <el-button type="primary" @click="dtlaccsave">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    title="新建合同"
    v-model="deadlineeditvisiable"
    width="840px"
    :show-close="false"
    center
    @close="deadlineclose"
  >
    <span>
      <el-form label-width="100px">
        <el-form-item label="合同期限">
          <el-date-picker
            v-model="nowdeadline.dataarea"
            type="daterange"
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="结束日期"
            @change="deadlinedataareachange"
          />
        </el-form-item>

        <el-form-item label="摘要">
          <el-input
            v-model="nowdeadline.remark"
            style="width: 210px,height : 80px"
            autocomplete="off"
            type="textarea"
          />
        </el-form-item>
      </el-form>
    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deadlineeditvisiable = false">取 消</el-button>
        <el-button type="primary" @click="dtldeallinesave">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    title="添加折扣明细"
    v-model="discountdtleditvisiable"
    width="840px"
    :show-close="false"
    @close="cleardiscountdtldata"
    center
  >
    <span>
      <div v-show="nowdiscount.dtltype == 1">
        <el-form label-width="100px">
          <el-form-item label="客户">
            <BuyerInfoSelectVue
              :code="discounteditbuyer"
              @datachange="discountbuyerselechangs"
            />
          </el-form-item>
          <el-form-item label="物料组">
            <GoodsgroupVue
              :code="discounteditgoodsgroup"
              @datachange="discountgoodsgroupselechangs"
            />
          </el-form-item>
          <el-form-item label="折扣率">
            <el-input-number
              :disabled="dialoginfo.modle == 'view'"
              v-model="editratio"
              style="width: 100px"
              :controls="false"
              @blur="editratioblur"
            />%
          </el-form-item>
        </el-form>
      </div>

      <div v-show="nowdiscount.dtltype == 2">
        <el-form label-width="100px">
          <el-form-item label="项目名称">
            <el-input
              v-model="discounteditxmname"
              style="width: 260px"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="折扣限额">
            <el-input-number
              :disabled="dialoginfo.modle == 'view'"
              v-model="editratio"
              style="width: 100px"
              :controls="false"
              @blur="editratioblur"
            />
          </el-form-item>
          <el-form-item label="是否核销">
            <el-select
              v-model="writeoff"
              style="width: 110px"
              :disabled="dialoginfo.modle == 'view'"
              placeholder="请选择"
            >
              <el-option
                v-for="item in yesorno"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="discountdtleditvisiable = false">取 消</el-button>
        <el-button type="primary" @click="editdiscountsave">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    :title="discounttitle"
    v-model="discountedvisiable"
    width="960px"
    :show-close="false"
    @close="clearnowdiscountssdata"
    center
  >
    <span>
      <el-form
        label-width="100px"
        inline
        ref="adddiscountdiform"
        v-model="nowdiscount"
      >
        <el-form-item label="生效时间" prop="dataarea">
          <el-date-picker
            v-model="nowdiscount.dataarea"
            type="daterange"
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="结束日期"
            @change="dataareachange"
          />
        </el-form-item>
        <el-form-item label="项目名称" prop="v_name">
          <el-input
            :disabled="dialoginfo.modle == 'view'"
            v-model="nowdiscount.v_name"
            style="width: 220px"
            autocomplete="off"
          />
        </el-form-item>
        

        <el-form-item label="折扣比率" v-show="nowdiscount.dtltype == 1">
          <el-input-number
            :disabled="dialoginfo.modle == 'view'"
            v-model="defaultratio"
            style="width: 100px"
            :controls="false"
            @blur="ratioblur"
          />%
        </el-form-item>

        <el-form-item label="备注">
          <el-input
            :disabled="dialoginfo.modle == 'view'"
            v-model="nowdiscount.remark"
            style="width: 320px"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>

    </span>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="discountedvisiable = false">取 消</el-button>
        <el-button type="primary" @click="dtldiscountsave">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script >
//import { ElMessage } from "element-plus";
//import GoodstypeCascaderVue from "../../../../../../controls/GoodstypeCascader.vue"
// import ContactsCascaderVue from "../../../../../../controls/ContactsCascader.vue"
import AccountinfoSelectVue from "@/controls/AccountinfoSelect.vue";
import BuyerInfoSelectVue from "@/controls/BuyerInformationSeltct.vue";
import GoodsgroupVue from "@/controls/GoodsgroupSelect.vue";

import { ElMessage, ElMessageBox } from "element-plus";
import axios from "axios";

export default {
  emits: ["changedata", "dialogclose"],
  components: { AccountinfoSelectVue, BuyerInfoSelectVue, GoodsgroupVue },
  props: {
    dialoginfo: { required: true },
    dialogdata: { required: true },
    isvisiable: {},
  },
  data() {
    const validateword = (rule, value, callback) => {
      if (value.toString().match("^[^a-z A-Z]?[^a-z A-Z 0-9]+")) {
        callback(new Error("只可输入字母"));
      } else if (value == "") {
        callback(new Error("标识不可为空"));
      } else {
        callback();
      }
    };
    return {
      deadlinstats: [
        { value: "1", label: "未作简表，原件未回" },
        { value: "2", label: "已做简表，原件未回" },
        { value: "3", label: "签约完成，原件已回" },
        { value: "4", label: "虚拟合同" },
      ],
      nowdeadline: {
        sid: 0,
        status: "1",
        remark: "",
        starttime: 0,
        endtime: 0,
      },
      yesorno: [
        { value: 1, label: "是" },
        { value: 99, label: "否" },
      ],
      accunits: [],

      yesorno2: [
        { value: "1", label: "是" },
        { value: "99", label: "否" },
      ],
      discounttypes: [
        { value: "1", label: "比例折扣" },
        { value: "2", label: "金额折扣" },
      ],
      nowdiscount: {
        dtltype: "1",
        starttime: 0,
        endtime: 0,
        v_name: "",
        dataarea: ["", ""],
      },
      atttype: "",
      discountedvisiable: false,
      deadlineeditvisiable: false,
      editratio: 0,
      discounteditxmname: "",
      writeoff: 1,
      discounteditgoodsgroup_v: "",
      discounteditgoodsgroup: "",
      discounteditbuyer: "",
      discounteditbuyer_v: "",
      discounttitle: "",
      period_day1: "",
      defaultratio: 0,
      period_day2: "",
      period_day3: "",
      indiscounts: [],
      outdiscounts: [],
      discountdtleditvisiable: false,
      jzday1s: [
        { value: 1, label: "一月" },
        { value: 2, label: "二月" },
        { value: 3, label: "三月" },
        { value: 4, label: "四月" },
        { value: 5, label: "五月" },
        { value: 6, label: "六月" },
        { value: 7, label: "七月" },
        { value: 8, label: "八月" },
        { value: 9, label: "九月" },
        { value: 10, label: "十月" },
        { value: 11, label: "十一月" },
        { value: 12, label: "十二月" },
      ],
      jzday2s: [],
      period_data1: "",
      period_data2: "",
      periodtype: 1,
      zqtype: [
        { value: 1, label: "日结" },
        { value: 2, label: "周结" },
        { value: 3, label: "月结" },
        { value: 4, label: "年结" },
      ],
      thisaccedit: {},
      acceditvisiable: false,
      accounteditdata: "",
      creditamount: 0,
      activeTab: "deadline",
      goodstypewi: "250px",
      thiseddata: {
        keword: "",
        goodstype1: "",
        goodstype2: "",
        goodstype3: "",
        goodstype4: "",
        goodstype5: "",
        goodstype6: "",
      },
      dtlvisiablegp: false,
      ssvalue: {},
      ssdatas: [],

      dtlvisiable: false,
      dtldata: { type: "1", v_name: "", mark: "", remark: "" },
      settletypes: [
        { label: "合同核销", value: '2' },
        { label: "客户核销", value: '3' },
      ],
      processtypes: [
        { label: "先款", value: '1' },
        { label: "先票", value: '2' },
      ],
      dtldatarules: {
        v_name: [
          { required: false, message: "本项目不可为空！！", trigger: "blur" },
        ],
        mark: [{ required: true, validator: validateword, trigger: "blur" }],
      },
      thisdata: {
        process_enable1: 1,
        process_enable2: 1,
        accountingunit: "",
        isview: 1,
      },
      visiable: false,
      yesno: [
        { label: "是", value: "1" },
        { label: "否", value: "99" },
      ],
      dtltypes: [
        { label: "通用数据", value: "1" },
        { label: "专用数据", value: "2" },
      ],
      thisrules: {
        v_name: [
          { required: true, message: "本项目不可为空！！", trigger: "blur" },
        ],
      },
      thisdiscrull: {
        v_name: [
          { required: true, message: "本项目不可为空！！", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    isvisiable: function () {
      if (this.isvisiable) {
        this.thisdata = JSON.parse(JSON.stringify(this.dialogdata));
        this.cleardtldata();
        this.visiable = true;
        this.creditamount = parseInt(this.thisdata.amount) / 100;
        this.thisdata.acclist = Array();
        this.thisdata.periods = Array();
        console.log("dialog get", this.dialogdata);
        console.log("dialog info ", this.dialoginfo);
        this.getdata();
        this.getindiscountsdata();
        this.getoutdiscountsdata();
      }
    },
    activeTab: function () {
      if (this.activeTab == "indiscount") {
        this.discounttitle = "票面折扣信息";
        this.nowdiscount.type = 1;
        this.atttype = 1;
      } else if (this.activeTab == "outdiscount") {
        this.discounttitle = "票外折扣信息";
        this.nowdiscount.type = 2;
        this.atttype = 2;
      } else {
        this.discounttitle = "";
        this.nowdiscount.type = 0;
        this.atttype = 0;
      }
    },
    discountdtleditvisiable: function () {
      if (this.discountdtleditvisiable) {
        this.discounteditgoodsgroup = "";
        this.discounteditgoodsgroup_v = "";
        this.discounteditbuyer = "";
        this.discounteditbuyer_v = "";
        this.editratio = 0;
        this.discounteditxmname = "";
        this.writeoff = 1;
      }
    },
  },
  mounted() {

    this.activeTab="deadline";
    axios
      .post("/getdicc/accountingunitgetlist", {
        data: { codename: "", status: "", code: "" },
      })
      .then((response) => {
        this.accunits = response;
      });
  },
  methods: {
    editdeadlinedata(val) {
      this.nowdeadline = val;
      this.deadlineeditvisiable = true;
      this.$nextTick(() => {
        // this.nowdiscount.dataarea = [
        //   response.begintime_date,
        //   response.endtime_date,
        // ];
        this.nowdeadline.dataarea = [
          new Date(parseInt(val.starttime)).getFullYear() +
            "-" +
            (new Date(parseInt(val.starttime)).getMonth() + 1 < 10
              ? "0" + (new Date(parseInt(val.starttime)).getMonth() + 1)
              : new Date(parseInt(val.starttime)).getMonth() + 1) +
            "-" +
            new Date(parseInt(val.starttime)).getDate(),
          new Date(parseInt(val.endtime)).getFullYear() +
            "-" +
            (new Date(parseInt(val.endtime)).getMonth() + 1 < 10
              ? "0" + (new Date(parseInt(val.endtime)).getMonth() + 1)
              : new Date(parseInt(val.endtime)).getMonth() + 1) +
            "-" +
            new Date(parseInt(val.endtime)).getDate(),
        ];
      });
    },
    hetongstatus(row, column, cellValue) {
      for (var i = 0; i < this.deadlinstats.length; i++) {
        if (this.deadlinstats[i].value == cellValue) {
          return this.deadlinstats[i].label;
        }
      }
    },
    dtldeallinesave() {
      console.log("deadline save", this.nowdeadline);
      if (typeof this.thisdata.deadlines == "undefined") {
        this.thisdata.deadlines = Array();
      }
      if (this.nowdeadline.sid > 0) {
        this.thisdata.deadlines.map((val) => {
          if (val.row_code == this.nowdeadline.row_code) {
            val.starttime = this.nowdeadline.starttime;
            val.endtime = this.nowdeadline.endtime;
            val.status = this.nowdeadline.status;
            val.status_v = this.hetongstatus([], [], this.nowdeadline.status);
            val.remark = this.nowdeadline.remark;
          }
        });
        this.deadlineeditvisiable = false;
      } else {
        axios
          .post("/" + this.dialoginfo.modlecode + "/savedeadline", {
            data: {
              starttime: this.nowdeadline.starttime,
              endtime: this.nowdeadline.endtime,
              status: this.nowdeadline.status,
              status_v: this.hetongstatus([], [], this.nowdeadline.status),
              remark: this.nowdeadline.remark,
              h_row_code: this.thisdata.row_code,
            },
          })
          .then((response) => {
            this.thisdata.deadlines.push(response);

            this.deadlineeditvisiable = false;
          })
          .catch(() => {});
      }
    },
    deadlineclose() {
      this.nowdeadline = {
        sid: 0,
        status: "1",
        remark: "",
        starttime: 0,
        endtime: 0,
      };
    },
    accountingunitselechangs(val) {
      console.log("accunselechange", val);
      this.thisdata.accountingunit = val.row_code;
    },
    formatterdtltype(row, column, value) {
      for (var i = 0; i < this.discounttypes.length; i++) {
        if (this.discounttypes[i].value == value) {
          return this.discounttypes[i].label;
        }
      }
      return "数据错误";
    },
    formatYesNo(row, column, value) {
      if (value == 1) {
        return "是";
      } else if (value == 99) {
        return "否";
      } else {
        return "数据错误";
      }
    },
    savediscount(val) {
      val.type = this.atttype;
      axios
        .post("/" + this.dialoginfo.modlecode + "/saveindiscount", {
          data: val,
        })
        .then(() => {
          console.log(this.nowdiscount.type);
          if (this.atttype == 1) {
            this.getindiscountsdata();
          } else if (this.atttype == 2) {
            this.getoutdiscountsdata();
          }
          this.discountedvisiable = false;
        })
        .catch(() => {});
    },
    dtldiscountsave() {
      console.log("nowdiscount before save", this.nowdiscount);

      if (
        this.nowdiscount.starttime == 0 ||
        this.nowdiscount.endtime == 0 ||
        this.nowdiscount.v_name == ""
      ) {
        ElMessage.error({
          message: "请填写折扣生效时间及折扣名称！！",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        return;
      }
      if (this.atttype == 1) {
        if (this.indiscounts.length > 0) {
          for (var i = 0; i < this.indiscounts.length; i++) {
            if (
              this.indiscounts[i].endtime >= this.nowdiscount.starttime &&
              this.indiscounts[i].begintime <= this.nowdiscount.endtime &&
              this.indiscounts[i].dtltype == 1 &&
              this.nowdiscount.dtltype == 1
            ) {
              ElMessage.error({
                message:
                  "生效时间与已存在的项目重叠。票折比例折扣同一时间仅允许存在一个有效方案！！",
                duration: 2000,
                dangerouslyUseHTMLString: true,
                center: false,
              });
              return;
            }
          }
        }
      } else if (this.atttype == 2) {
        console.log("Error: InvalidStateError   ");
      }
      this.nowdiscount.defaultratio = (this.defaultratio * 10000).toFixed(0);

      this.nowdiscount.h_row_code = this.thisdata.row_code;
      this.savediscount(this.nowdiscount);
    },
    copyindiscountdata(val) {
      console.log("copyid", val);
    },
    delindiscountdata(val) {
      console.log("delindiscountdata", val);
      ElMessageBox.confirm(
        "确定要执行删除操作吗，本操作不可撤销！！！！！",
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        axios
          .post("/" + this.dialoginfo.modlecode + "/deldiscount", {
            data: val,
          })
          .then(() => {
            if (val.type == 1) {
              console.log("");
              this.getindiscountsdata();
            } else if (console.log(""));
          })
          .catch(() => {});
      });
    },
    delindisratiodata(val) {
      console.log("delet edit disr", val);
      var i;
      if (this.nowdiscount.dtltype == 1) {
        for (i = 0; i < this.nowdiscount.ratios.length; i++) {
          if (this.nowdiscount.ratios[i].row_code == val.row_code) {
            this.nowdiscount.ratios.splice(i, 1);
            break;
          }
        }
        if (val.sid > 0) {
          if (typeof this.nowdiscount.deletratios == "undefined") {
            this.nowdiscount.deletratios = Array();
          }
          this.nowdiscount.deletratios.push(val);
        }
      } else if (this.nowdiscount.dtltype == 2) {
        for (i = 0; i < this.nowdiscount.quotas.length; i++) {
          if (this.nowdiscount.quotas[i].row_code == val.row_code) {
            this.nowdiscount.quotas.splice(i, 1);
            break;
          }
        }
        if (val.sid > 0) {
          if (typeof this.nowdiscount.deletquotas == "undefined") {
            this.nowdiscount.deletquotas = Array();
          }
          this.nowdiscount.deletquotas.push(val);
        }
      }
    },
    editratioblur(val) {
      console.log("zzzzratioblur", val);
      if (typeof this.editratio == "undefined") {
        this.editratio = 0;
      }
    },
    discountgoodsgroupselechangs(val) {
      console.log("discount", val);
      this.discounteditgoodsgroup = val.row_code;
      this.discounteditgoodsgroup_v = val.v_name;
    },
    discountbuyerselechangs(val) {
      console.log("discountbuyerselechangs", val);
      this.discounteditbuyer = val.row_code;
      this.discounteditbuyer_v = val.v_name;
    },
    clearnowdiscountssdata() {
      this.nowdiscount = {
        dtltype: "1",
        starttime: 0,
        endtime: 0,
        v_name: "",
        dataarea: ["", ""],
      };
      this.defaultratio = 0;
      console.log("clearnowdiscountssdata", this.nowdiscount);
    },

    cleardiscountdtldata() {},
    editdiscountsave() {
      this.$bus.$emit("showloading");
      if (this.nowdiscount.dtltype == 1) {
        if (typeof this.editratio == "undefined" || this.editratio <= 0) {
          ElMessage.error({
            message: "扣率未按要求填写！！",
            duration: 2000,
            dangerouslyUseHTMLString: true,
            center: false,
          });
          this.$bus.$emit("hideloading");
          return;
        }
        if (this.discounteditbuyer == "" && this.discounteditgoodsgroup == "") {
          ElMessage.error({
            message: "客户及物料组应最少填写一项！！",
            duration: 2000,
            dangerouslyUseHTMLString: true,
            center: false,
          });
          this.$bus.$emit("hideloading");
          return;
        }

        if (typeof this.nowdiscount.ratios == "undefined") {
          this.nowdiscount.ratios = Array();
        }
        console.log("票面，比例保存", "");
        let needadd = true;
        this.nowdiscount.ratios.map((val) => {
          if (
            val.buyercode == this.discounteditbuyer &&
            val.goodsgroupcode == this.discounteditgoodsgroup
          ) {
            ElMessage.error({
              message: "已存在相同的客户物料组合，不可重复添加",
              duration: 2000,
              dangerouslyUseHTMLString: true,
              center: false,
            });
            needadd = false;
          }
        });
        if (needadd) {
          if (typeof this.nowdiscount.ratios == "undefined") {
            this.nowdiscount.ratios = Array();
          }
          this.nowdiscount.ratios.push({
            sid: 0,
            row_code:
              "ad" +
              Date.parse(new Date()) +
              "-" +
              parseInt(Math.random() * (9999 - 1000 + 1) + 1000, 10),
            buyercode: this.discounteditbuyer,
            goodsgroupcode: this.discounteditgoodsgroup,
            buyercode_v: this.discounteditbuyer_v,
            goodsgroupcode_v: this.discounteditgoodsgroup_v,
            ratio: (this.editratio * 10000).toFixed(0),
          });
        }
      } else if (this.nowdiscount.dtltype == 2) {
        if (typeof this.nowdiscount.quotas == "undefined") {
          this.nowdiscount.quotas = Array();
        }
        let needadd = true;
        this.nowdiscount.quotas.map((val) => {
          if (val.v_name == this.discounteditxmname) {
            ElMessage.error({
              message: "填写错误，项目名称已经存在！！",
              duration: 2000,
              dangerouslyUseHTMLString: true,
              center: false,
            });
            needadd = false;
          }
        });

        if (this.editratio < 0 || this.discounteditxmname == "") {
          ElMessage.error({
            message:
              "填写错误，项目名称不可为空且折扣限额不可为负数，请检查！！",
            duration: 2000,
            dangerouslyUseHTMLString: true,
            center: false,
          });
          this.$bus.$emit("hideloading");
          return;
        }
        console.log("票内金额", "");
        if (needadd) {
          if (typeof this.nowdiscount.quotas == "undefined") {
            this.nowdiscount.quotas = Array();
          }
          this.nowdiscount.quotas.push({
            sid: 0,
            row_code:
              "ad" +
              Date.parse(new Date()) +
              "-" +
              parseInt(Math.random() * (9999 - 1000 + 1) + 1000, 10),
            v_name: this.discounteditxmname,
            amount: (this.editratio * 100).toFixed(0),
            balance: (this.editratio * 100).toFixed(0),
            iswriteoff: this.writeoff,
          });
        }
      }

      this.discountdtleditvisiable = false;
      this.$bus.$emit("hideloading");
    },
    opdiscountdata(val) {
      this.$bus.$emit("showloading");
      console.log("opdiscountdata", val);
      //nowdiscount
      //nowdiscount.dataarea
      axios
        .post("/" + this.dialoginfo.modlecode + "/getnowdisount", {
          data: val,
        })
        .then((response) => {
          this.defaultratio = parseFloat(
            (response.defaultratio / 10000).toFixed(2)
          );
          this.$nextTick(() => {
            this.nowdiscount.dataarea = [
              response.begintime_date,
              response.endtime_date,
            ];
          });
          this.nowdiscount = response;
          this.discountedvisiable = true;
        })
        .catch(() => {});

      this.$bus.$emit("hideloading");
    },
    tabschange(val) {
      console.log("tabs change ", val);
    },
    formatter_data(row, column, cellValue) {
      if(cellValue==""){
        if(column['property']=='data1'){
          return '发货日当天';
        }
        if(column['property']=='data2'){
          return '对账日当天';
        }
        if(column['property']=='data3'){
          return '开票日当天';
        }
      }
      return cellValue;
    },
    delperiodsdata(val) {
      for (var i = 0; i < this.thisdata.periods.length; i++) {
        if (this.thisdata.periods[i].row_code == val.row_code) {
          this.thisdata.periods.splice(i, 1);
        }
      }
      if (val.sid > 0) {
        if (typeof this.thisdata.delpriods == "undefined") {
          this.thisdata.delpriods = Array();
        }
        this.thisdata.delpriods.push({
          row_code: val.row_code,
        });
      }
    },
    deadlinedataareachange(val) {
      if (val != null) {
        this.nowdeadline.starttime = val[0].getTime();
        this.nowdeadline.endtime = val[1].getTime() + 86399999;
      } else {
        this.nowdeadline.starttime = 0;
        this.nowdeadline.endtime = 0;
      }
      console.log("datachang", val, this.nowdiscount);
    },
    dataareachange(val) {
      if (val != null) {
        this.nowdiscount.starttime = val[0].getTime();
        this.nowdiscount.endtime = val[1].getTime() + 86399999;
      } else {
        this.nowdiscount.starttime = 0;
        this.nowdiscount.endtime = 0;
        this.thisdiscrull.dataarea = [
          { required: false, validator: this.validatedataera, trigger: "blur" },
        ];
        this.$refs.adddiscountdiform.validateField(
          "dataarea",
          (errorMessage) => {
            if (errorMessage) {
              console.log(errorMessage);
            }
          }
        );
      }
      console.log("datachang", val, this.nowdiscount);
    },
    delaccdata(val) {
      for (var i = 0; i < this.thisdata.acclist.length; i++) {
        if (this.thisdata.acclist[i].row_code == val.row_code) {
          this.thisdata.acclist.splice(i, 1);
        }
      }
      if (this.thisdata.accdefault == val.bankcode) {
        if (this.thisdata.acclist.length > 0) {
          this.thisdata.accdefault = this.thisdata.acclist[0].bankcode;
        } else {
          this.thisdata.accdefault = "";
        }
      }
      if (val.sid > 0) {
        if (typeof this.thisdata.delaccdata == "undefined") {
          this.thisdata.delaccdata = Array();
        }
        this.thisdata.delaccdata.push(val);
      }
    },
    periodtypecg(val) {
      if (val == 2) {
        this.jzday2s = [
          { value: 1, label: "周一" },
          { value: 2, label: "周二" },
          { value: 3, label: "周三" },
          { value: 4, label: "周四" },
          { value: 5, label: "周五" },
          { value: 6, label: "周六" },
          { value: 7, label: "周日" },
        ];
      }
      if (val > 2) {
        this.jzday2s = [
          { value: 1, label: "1日" },
          { value: 2, label: "2日" },
          { value: 3, label: "3日" },
          { value: 4, label: "4日" },
          { value: 5, label: "5日" },
          { value: 6, label: "6日" },
          { value: 7, label: "7日" },
          { value: 8, label: "8日" },
          { value: 9, label: "9日" },
          { value: 10, label: "10日" },
          { value: 11, label: "11日" },
          { value: 12, label: "12日" },
          { value: 13, label: "13日" },
          { value: 14, label: "14日" },
          { value: 15, label: "15日" },
          { value: 16, label: "16日" },
          { value: 17, label: "17日" },
          { value: 18, label: "18日" },
          { value: 19, label: "19日" },
          { value: 20, label: "20日" },
          { value: 21, label: "21日" },
          { value: 22, label: "22日" },
          { value: 23, label: "23日" },
          { value: 24, label: "24日" },
          { value: 25, label: "25日" },
          { value: 26, label: "26日" },
          { value: 27, label: "27日" },
          { value: 28, label: "28日" },
          { value: 32, label: "月末" },
        ];
      }
      this.period_data1 = 1;
      this.period_data2 = 1;
      this.period_day1 = 0;
      this.period_day2 = 0;
      this.thisdata.periods.map((val) => {
        if (val.sid > 0) {
          if (typeof this.thisdata.delpriods == "undefined") {
            this.thisdata.delpriods = Array();
          }
          this.thisdata.delpriods.push(val);
        }
      });

      this.thisdata.periods = [];
    },
    setradioRow(val) {
      this.thisdata.accdefault = val;
    },
    accoutselechangs(val) {
      console.log("account change ", val);
      this.thisaccedit = val;
      this.thisaccedit.sid = 0;
    },
    clearaccdata() {
      console.log("clear accdata");
      this.thisaccedit = {};

      this.accounteditdata = "";
    },
    openaddaccount() {
      console.log("openaddaccount");
      this.acceditvisiable = true;
      this.thisaccedit = {};

      this.accounteditdata = "";
    },
    dtlaccsave() {
      this.$bus.$emit("showloading");
      console.log("this.thisdata.acclist", this.thisdata.acclist);
      for (var i = 0; i < this.thisdata.acclist.length; i++) {
        if (this.thisdata.acclist[i].v_num == this.thisaccedit.v_num) {
          ElMessage.error({
            message: "账号已存在，请重新选择！！！",
            duration: 2000,
            dangerouslyUseHTMLString: true,
            center: false,
          });
          this.$bus.$emit("hideloading");
          return;
        }
      }
      if (this.thisdata.acclist.length == 0) {
        this.thisdata.accdefault = this.thisaccedit.row_code;
      }

      if (typeof this.thisdata.acclist == "undefined") {
        this.thisdata.acclist = Array();
      }
      this.thisdata.acclist.push({
        sid: 0,
        row_code:
          "ad" +
          Date.parse(new Date()) +
          "-" +
          parseInt(Math.random() * (9999 - 1000 + 1) + 1000, 10),
        ver: 1,
        bankcode: this.thisaccedit.row_code,
        v_bank: this.thisaccedit.v_bank,
        v_name: this.thisaccedit.v_name,
        v_num: this.thisaccedit.v_num,
      });
      this.acceditvisiable = false;
      this.$bus.$emit("hideloading");
    },

    enablechange(pre, next, da) {
      switch (da) {
        case "enable1":
          this.thisdata.process_enable1 = pre ? 1 : 0;
          break;

        case "enable2":
          this.thisdata.process_enable2 = pre ? 1 : 0;
          break;
      }
    },
    ratioblur() {
      console.log("ratioblur", this.defaultratio);
      if (typeof this.defaultratio == "undefined") {
        this.defaultratio = 0;
      } else {
        this.nowdiscount.defaultratio = (this.defaultratio * 1000000).toFixed(
          0
        );
      }
    },
    numnerblur() {
      console.log("numnerblur", this.creditamount);
      if (typeof this.creditamount == "undefined") {
        this.creditamount = 0;
      } else {
        this.thisdata.amount = (this.creditamount * 100).toFixed(0);
      }
    },
    deldata(val) {
      if (val.sid > 0) {
        if (typeof this.thisdata.deletdtl == "undefined") {
          this.thisdata.deletdtl = Array();
        }
        this.thisdata.deletdtl.push(val);
      }
      for (var i = 0; i < this.thisdata.dtl.length; i++) {
        if (this.thisdata.dtl[i].row_code == val.row_code) {
          this.thisdata.dtl.splice(i, 1);
          return;
        }
      }
    },
    addzqs() {
      let temarray = Array();

      //   zqtype: [
      //     { value: 1, label: "日结" },
      //     { value: 2, label: "周结" },
      //     { value: 3, label: "月结" },
      //     { value: 4, label: "年结" },
      //   ],
      temarray = {
        sid: 0,
        row_code:
          "ad" +
          Date.parse(new Date()) +
          "-" +
          parseInt(Math.random() * (9999 - 1000 + 1) + 1000, 10),
        data1: this.period_day1,
        data2: this.period_day2,
        data3: this.period_day3,
      };
      let errorcodes = "";
      const zztype1 = /^(\d+)天(周[一二三四五六日])$/;
      const zztype2 = /^([1-9]|[12]\d|3[01])日$/;
      const zztype3 = /^(周[一二三四五六日])$/;
      const zztype4 = /^(\d+)天([1-9]|[12]\d|3[01])日$/;
      const zztype5 = /^(\d+)天$/;

      if (this.period_day1 != "") {
        let checkst = this.period_day1;
        let res1 = checkst.match(zztype1);
        let res2 = checkst.match(zztype2);
        let res3 = checkst.match(zztype3);
        let res4 = checkst.match(zztype4);
        let res5 = checkst.match(zztype5);

        if (res1) {
          console.log("aaa", res1);
        } else if (res2) {
          console.log("aaa", res2);
        } else if (res3) {
          console.log("aaa", res3);
        } else if (res4) {
          console.log("aaa", res4);
        } else if (res5) {
          console.log("aaa", res5);
        } else {
          errorcodes += "对账日、";
        }
      }
      if (this.period_day2 != "") {
        let checkst = this.period_day2;
        let res1 = checkst.match(zztype1);
        let res2 = checkst.match(zztype2);
        let res3 = checkst.match(zztype3);
        let res4 = checkst.match(zztype4);
        let res5 = checkst.match(zztype5);

        if (res1) {
          console.log("aaa", res1);
        } else if (res2) {
          console.log("aaa", res2);
        } else if (res3) {
          console.log("aaa", res3);
        } else if (res4) {
          console.log("aaa", res4);
        } else if (res5) {
          console.log("aaa", res5);
        } else {
          errorcodes += "开票日、";
        }
      }

      if (this.period_day3 != "") {
        let checkst = this.period_day3;
        let res1 = checkst.match(zztype1);
        let res2 = checkst.match(zztype2);
        let res3 = checkst.match(zztype3);
        let res4 = checkst.match(zztype4);
        let res5 = checkst.match(zztype5);

        if (res1) {
          console.log("aaa", res1);
        } else if (res2) {
          console.log("aaa", res2);
        } else if (res3) {
          console.log("aaa", res3);
        } else if (res4) {
          console.log("aaa", res4);
        } else if (res5) {
          console.log("aaa", res5);
        } else {
          errorcodes += "回款日、";
        }
      }
      if (errorcodes != "") {
        ElMessage.error({
          message:
            errorcodes.slice(0, -1) + "填写内容存在错误，请修改后重试！！！",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        return;
      }
      let ishave=false;
      this.thisdata.periods.forEach((item) => {
        if (
          this.period_data2 == item.data2 &&
          this.period_data1 == item.data1&&
          this.period_data3 == item.data3
        ) {
          ishave = true;
        }
      });
      if(ishave){
        ElMessage.error({
          message:
             "已存在相同数据，请修改后重试！！！",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        return;
      }

      this.thisdata.periods.push(temarray);
      this.period_day1 = "";
      this.period_day2 = "";
      this.period_day3 = "";
      return;

      // if (this.periodtype == 1) {
      //   if (this.thisdata.periods.length > 0) {
      //     ElMessage.error({
      //       message: "日结方式仅可设置一组结算周期数据！！！",
      //       duration: 2000,
      //       dangerouslyUseHTMLString: true,
      //       center: false,
      //     });
      //   } else {
      //     temarray.data1 = 0;
      //     if (typeof this.thisdata.periods == "undefined") {
      //       this.thisdata.periods = Array();
      //     }
      //     this.thisdata.periods.push(temarray);
      //   }
      // } else if (this.periodtype == 2) {
      //   let ishave = false;
      //   this.thisdata.periods.forEach((item) => {
      //     if (this.period_data2 == item.data2) {
      //       ishave = true;
      //     }
      //   });
      //   if (!ishave) {
      //     temarray.data1 = 0;
      //     if (typeof this.thisdata.periods == "undefined") {
      //       this.thisdata.periods = Array();
      //     }
      //     this.thisdata.periods.push(temarray);
      //   }
      // } else if (this.periodtype == 3) {
      //   let ishave = false;
      //   this.thisdata.periods.forEach((item) => {
      //     if (this.period_data2 == item.data2) {
      //       ishave = true;
      //     }
      //   });
      //   if (!ishave) {
      //     temarray.data1 = 0;
      //     if (typeof this.thisdata.periods == "undefined") {
      //       this.thisdata.periods = Array();
      //     }
      //     this.thisdata.periods.push(temarray);
      //   }
      // } else {
      //   let ishave = false;
      //   this.thisdata.periods.forEach((item) => {
      //     if (
      //       this.period_data2 == item.data2 &&
      //       this.period_data1 == item.data1
      //     ) {
      //       ishave = true;
      //     }
      //   });
      //   if (!ishave) {
      //     if (typeof this.thisdata.periods == "undefined") {
      //       this.thisdata.periods = Array();
      //     }
      //     this.thisdata.periods.push(temarray);
      //   }
      // }
    },

    dtlsave() {
      this.$bus.$emit("showloading");
      let needadd = true;
      if (
        this.thiseddata.goodstype1 == "" &&
        this.thiseddata.goodstype2 == "" &&
        this.thiseddata.goodstype3 == "" &&
        this.thiseddata.goodstype4 == "" &&
        this.thiseddata.goodstype5 == "" &&
        this.thiseddata.goodstype6 == "" &&
        this.thiseddata.keword == ""
      ) {
        ElMessage.error({
          message: "关键字及物料分类不可全部为空，请至少填写一项！！！",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          center: false,
        });
        this.$bus.$emit("hideloading");
        return;
      }
      this.thisdata.dtl.map((val) => {
        if (
          val.keword == this.thiseddata.keword &&
          val.goodstype1 == this.thiseddata.goodstype1 &&
          val.goodstype2 == this.thiseddata.goodstype2 &&
          val.goodstype3 == this.thiseddata.goodstype3 &&
          val.goodstype4 == this.thiseddata.goodstype4 &&
          val.goodstype5 == this.thiseddata.goodstype5 &&
          val.goodstype6 == this.thiseddata.goodstype6
        ) {
          ElMessage.error({
            message: "已存在相同数据！！！",
            duration: 2000,
            dangerouslyUseHTMLString: true,
            center: false,
          });
          needadd = false;
        }
      });
      if (needadd) {
        this.thiseddata.status = 1;
        this.thiseddata.sid = 0;
        this.thiseddata.row_code =
          "ad" +
          Date.parse(new Date()) +
          "-" +
          parseInt(Math.random() * (9999 - 1000 + 1) + 1000, 10);
        this.thiseddata.ordernum = this.thisdata.dtl.length;
        this.thiseddata.h_row_code = this.thisdata.row_code;
        if (typeof this.thisdata.dtl == "undefined") {
          this.thisdata.dtl = Array();
        }
        this.thisdata.dtl.push(JSON.parse(JSON.stringify(this.thiseddata)));
      }
      this.dtlvisiable = false;
      this.$bus.$emit("hideloading");
    },
    cleardtldata() {
      this.thiseddata.sid = 0;
      this.thiseddata.row_code = "";
      this.thiseddata.h_row_code = "";
      this.thiseddata.goodstype1 = "";
      this.thiseddata.goodstype2 = "";
      this.thiseddata.goodstype3 = "";
      this.thiseddata.goodstype4 = "";
      this.thiseddata.goodstype5 = "";
      this.thiseddata.goodstype6 = "";
      this.thiseddata.keword = "";
      this.thiseddata.ordernum = "";
      this.thiseddata.goodstype1_v = "";
      this.thiseddata.goodstype2_v = "";
      this.thiseddata.goodstype3_v = "";
      this.thiseddata.goodstype4_v = "";
      this.thiseddata.goodstype5_v = "";
      this.thiseddata.goodstype6_v = "";
      this.thiseddata.status = "";
      console.log("closedtladd", this.thiseddata);
    },

    tableheadClass() {
      return "text-align:center";
    },

    getdata() {
      this.$bus.$emit("showloading");
      axios
        .post("/" + this.dialoginfo.modlecode + "/getdetails", {
          data: this.thisdata,
        })
        .then((response) => {
          this.thisdata = response;
          this.thisdata.process_enable1 = parseInt(
            this.thisdata.process_enable1
          );
          this.thisdata.process_enable2 = parseInt(
            this.thisdata.process_enable2
          );
          this.thisdata.isview = parseInt(this.thisdata.isview);
          console.log("getresponse data", this.thisdata);
          if (typeof response.periods[0].type == "undefined") {
            this.periodtype = 1;
          } else {
            this.periodtype = parseInt(response.periods[0].type);
          }
          if (this.periodtype == 2) {
            this.jzday2s = [
              { value: 1, label: "周一" },
              { value: 2, label: "周二" },
              { value: 3, label: "周三" },
              { value: 4, label: "周四" },
              { value: 5, label: "周五" },
              { value: 6, label: "周六" },
              { value: 7, label: "周日" },
            ];
          }
          if (this.periodtype > 2) {
            this.jzday2s = [
              { value: 1, label: "1日" },
              { value: 2, label: "2日" },
              { value: 3, label: "3日" },
              { value: 4, label: "4日" },
              { value: 5, label: "5日" },
              { value: 6, label: "6日" },
              { value: 7, label: "7日" },
              { value: 8, label: "8日" },
              { value: 9, label: "9日" },
              { value: 10, label: "10日" },
              { value: 11, label: "11日" },
              { value: 12, label: "12日" },
              { value: 13, label: "13日" },
              { value: 14, label: "14日" },
              { value: 15, label: "15日" },
              { value: 16, label: "16日" },
              { value: 17, label: "17日" },
              { value: 18, label: "18日" },
              { value: 19, label: "19日" },
              { value: 20, label: "20日" },
              { value: 21, label: "21日" },
              { value: 22, label: "22日" },
              { value: 23, label: "23日" },
              { value: 24, label: "24日" },
              { value: 25, label: "25日" },
              { value: 26, label: "26日" },
              { value: 27, label: "27日" },
              { value: 28, label: "28日" },
              { value: 32, label: "月末" },
            ];
          }
          this.period_data1 = 1;
          this.period_data2 = 1;
          this.period_day1 = 0;
          this.period_day2 = 0;

          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    getindiscountsdata() {
      this.$bus.$emit("showloading");
      axios
        .post("/" + this.dialoginfo.modlecode + "/getindiscountdetails", {
          data: this.thisdata,
        })
        .then((response) => {
          this.indiscounts = response;
          console.log(response);
          console.log(this.indiscounts);

          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },

    getoutdiscountsdata() {
      this.$bus.$emit("showloading");
      axios
        .post("/" + this.dialoginfo.modlecode + "/getoutdiscountdetails", {
          data: this.thisdata,
        })
        .then((response) => {
          this.outdiscounts = response;
          console.log(response);
          console.log(this.outdiscounts);

          this.$bus.$emit("hideloading");
        })
        .catch(() => {
          this.$bus.$emit("hideloading");
        });
    },
    cleardata() {
      //this.thisdata = {};
      this.$refs[this.dialoginfo.modlecode + "dialogform"].clearValidate([
        "v_name",
      ]);
      console.log("closedialog");
      this.$emit("dialogclose", {});
    },
    savedata() {
      console.log("savedata", this.thisdata);

      this.$bus.$emit("showloading");
      this.$refs[this.dialoginfo.modlecode + "dialogform"].validate((val) => {
        if (val) {
          axios
            .post("/" + this.dialoginfo.modlecode + "/save", {
              data: this.thisdata,
            })
            .then(() => {
              this.$emit("changedata", {});
              this.visiable = false;
              this.$bus.$emit("hideloading");
            })
            .catch(() => {
              this.$bus.$emit("hideloading");
            });
        } else {
          this.$bus.$emit("hideloading");
        }
      });
      //this.$bus.$emit("hideloading");
    },

    opendtladd() {
      this.dtlvisiable = true;
    },
  },
};
</script>
<style scoped>
:deep(.el-transfer-panel) {
  width: 300px;
}

:deep(.el-input-number .el-input__inner) {
  text-align: right;
}
</style>
