<template>
    <el-table
      :data="tabledata"
      style="width: 100%; margin-top: 5px"
      :height="tableheight"
      :header-cell-style="tableheadClass"
      stripe
      row-key="row_code"
      border
      default-expand-all
      :tree-props="{}"
    >
      <el-table-column
        prop="doc_date"
        label="单据日期"
        width="110"
        :formatter="dateformart"
        align="center"
      >
      </el-table-column>
  
      <el-table-column prop="v_name" label="合同名称" width="320" align="left">
      </el-table-column>
  
      <el-table-column
        prop="status"
        label="单据状态"
        width="180"
        :formatter="statusformart"
      >
      </el-table-column>
  
      <el-table-column prop="remark" label="摘要" width="260"> </el-table-column>
  
      <el-table-column width="120">
        <template #header>
          <el-button
            @click="openadd()"
            icon="el-icon-plus"
            size="small"
            :disabled="!operar.add"
            >增加</el-button
          >
        </template>
        <template #default="scope">
          <el-button @click="openview(scope.row)" type="text" size="small"
            >查看</el-button
          >
          <el-button
            @click="openedit(scope.row)"
            type="text"
            size="small"
            :disabled="!operar.edit"
            v-show="scope.row.status != 99 && viewlist.edit"
          >
            编辑
          </el-button>
          <el-button
            @click="deldata(scope.row)"
            type="text"
            size="small"
            v-show="scope.row.status != 99 && viewlist.del"
            :disabled="!operar.del"
            >作废</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagenow"
      :page-sizes="[50, 100, 300, 500, 1000]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalrow"
    >
    </el-pagination>
  

  </template>
  <script >

  
  //import { ElMessage } from "element-plus";
  //import axios from "axios";
  
  import { postback } from "@/publiccomm";
  
  export default {
    emits: [],
    props: {
      searchdata: { required: true },
      selfmodul: { required: true },
      areaheight: { required: true },
    },
    data() {
      return {
        dialogvis: false,
        cdvisiable: false,
        tableheight: 60,
  
        contactinfo: "",
  
        totalrow: 0,
        pagenow: 1,
        pagesize: 100,
  
        dialoginfo: { modlecode: this.selfmodul["code"] },
        dialogdata: {},
        tabledata: [],
        mysearch: {},
        operar: {
          conditionstr: "",
          add: false,
          edit: false,
          del: false,
          check: false,
          uncheck: false,
        },
  
        viewlist: {
          add: false,
          edit: false,
          del: false,
          check: false,
          uncheck: false,
        },
      };
    },
    mounted() {
      let operardata = JSON.parse(localStorage.getItem("authlist"));
      let controldata = operardata[this.selfmodul["code"]].operation;
      console.log(controldata);
      this.operar.add = (controldata & 128) == 128;
      this.operar.del = (controldata & 64) == 64;
      this.operar.edit = (controldata & 32) == 32;
      this.operar.check = (controldata & 16) == 16;
      this.operar.uncheck = (controldata & 8) == 8;
      this.operar.conditionstr = operardata[this.selfmodul["code"]].conditionstr;
  
      operardata = JSON.parse(localStorage.getItem("viewlist"));
      controldata = operardata[this.selfmodul["code"]].operation;
      this.viewlist.add = (controldata & 128) == 128;
      this.viewlist.del = (controldata & 64) == 64;
      this.viewlist.edit = (controldata & 32) == 32;
      this.viewlist.check = (controldata & 16) == 16;
      this.viewlist.uncheck = (controldata & 8) == 8;
  
      console.log(this.operar);
      this.flashdata();
      this.dialoginfo.operar = this.operar;
      this.dialoginfo.viewlist = this.viewlist;
  
      console.log("areaheigh", this.areaheight);
    },
    methods: {
      chcat() {
        this.$bus.$emit("showloading");
  
        
      },
      clearccdata() {
        this.contactinfo = "";
      },
      contactscg(val) {
        this.contactinfo = val[0].value;
      },
      statusformart(row, column, cellValue) {
        if (cellValue == 0) {
          return "待确认";
        }
        if (cellValue == 50) {
          return "已确认";
        }
        if (cellValue == 99) {
          return "作废";
        }
        return "数据错误";
      },
      dateformart(row, column, cellValue) {
        if (cellValue == 0) {
          return "";
        }
        const date = new Date(parseInt(cellValue));
        const dateNumFun = (num) => (num < 10 ? `0${num}` : num);
        const [Y, M, D, h, m, s] = [
          date.getFullYear(),
          dateNumFun(date.getMonth() + 1),
          dateNumFun(date.getDate()),
          dateNumFun(date.getHours()),
          dateNumFun(date.getMinutes()),
          dateNumFun(date.getSeconds()),
        ];
        var rtdate = `${Y}-${M}-${D} ${h}:${m}:${s}`;
        rtdate = `${Y}-${M}-${D}`;
        return rtdate;
      },
  
      handleSizeChange(val) {
        let pgs = Math.ceil(this.totalrow / val);
        this.pagesize = val;
        if (pgs >= this.pagenow) {
          this.flashdata();
        }
      },
      handleCurrentChange(val) {
        this.pagenow = val;
        this.flashdata();
      },
      editclose(data) {
        this.dialogvis = false;
        console.log(data);
      },
      getlistback(request) {
        if (request != null) {
          request = JSON.parse(request);
          console.log("callback", request.total);
          this.tabledata = request["rows"];
          this.totalrow = request["total"] * 1;
        }
  
        this.$bus.$emit("hideloading");
        this.$bus.$emit("hideloading");
      },
      flashdata() {
        this.$bus.$emit("showloading");
        let mysearchdata = JSON.parse(JSON.stringify(this.searchdata));
        mysearchdata.pagenow = this.pagenow;
        mysearchdata.pagesize = this.pagesize;
  
        postback(
          "/" + this.selfmodul["code"] + "/getlist",
          mysearchdata,
          this.getlistback
        );
  
        // axios
        //     .post("/" + this.selfmodul['code'] + "/getlist", { data: mysearchdata })
        //     .then((response) => {
  
        //         this.tabledata = response['rows'];
        //         this.totalrow = response['total'] * 1;
  
        //         this.$bus.$emit("hideloading");
  
        //     })
        //     .catch(() => {
        //         this.$bus.$emit("hideloading");
        //     });
      },
      openview(data) {
        console.log("view", data);
        this.dialoginfo.name = "查看" + this.selfmodul["name"] + "";
        this.dialoginfo.modle = "view";
        this.dialogdata = data;
        this.dialogvis = true;
      },
  
      openedit(data) {
        console.log("edit", data);
        this.dialoginfo.name = "编辑" + this.selfmodul["name"] + "";
        this.dialoginfo.modle = "edit";
        this.dialogdata = data;
        this.dialogvis = true;
      },

  
  
  
      tableheadClass() {
        return "text-align:center";
      },
    },
    watch: {
      searchdata: function () {
        if (JSON.stringify(this.mysearch) != JSON.stringify(this.searchdata)) {
          //查询条件变动，重新拉取数据
          this.mysearch = JSON.parse(JSON.stringify(this.searchdata));
          this.flashdata();
        }
      },
      areaheight: function () {
        this.$nextTick(() => {
          this.tableheight = this.areaheight - 284;
        });
      },
    },
  };
  </script>
  <style scoped>
  </style>